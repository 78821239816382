import apiCall from "@/services/api.js"

const state = {
    orders:[
        
]
}
const actions  = {
    getOrders({commit,state}){
      if(state.orders.length <=1){
        commit("SET_LOADING");
        apiCall.getOrders().then((response) =>{
          console.log(response.data)
          commit("UNSET_LOADING");
          commit("SET_ORDERS", response.data)
        }).catch((err) => {
          console.log(err.message)
        })
      }
    }
}
const mutations = {
  SET_ORDERS(state, data){
    state.orders = data
  }
}

export default {state,actions,mutations}