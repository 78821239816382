<template>
  <div>
    <h1>Home</h1>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";

export default {
  name: "Home",
  components: {},
  computed: {
    ...mapState({
      manage: (state) => state.manage,
    }),
  },
  mounted() {},
};
</script>
