<template>
  <div>
    <b-navbar toggleable="lg" type="light" class="navbar">
      <b-navbar-brand :to="{ name: 'Home' }">stickerfive</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown text="Shop" left>
            <b-dropdown-item :to="{ name: 'Orders' }"
              >Bestellungen</b-dropdown-item
            >
            <b-dropdown-item :to="{ name: 'Reorder' }"
              >Nachbestellung</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown text="Account" right>
            <b-dropdown-item @click="logout()">Logout</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      localStorage.removeItem("jwt");
      localStorage.removeItem("user");
      this.$store.commit("UNSET_USER");
      this.$router.push({ name: "Login" });
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
#nav div nav .bg-light {
  background-color: transparent;
}
#nav {
  .navbar-brand {
    font-weight: bold;
  }
  .nav-item .dropdown-menu {
    border: 0px !important;
  }
}
</style>
