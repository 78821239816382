<template>
  <div>
    <h2 class="mb-3">
      <b-button
        variant="light"
        class="mr-2"
        @click="$router.push({ name: 'Orders' })"
        ><b-icon-chevron-left></b-icon-chevron-left
      ></b-button>
      Bestellung {{ item.orderNo }}
    </h2>
    <b-row v-if="loading">
      <b-spinner
        v-if="loading"
        class="mx-auto mt-5"
        label="Loading..."
        style="width: 3rem; height: 3rem;"
      ></b-spinner>
    </b-row>
    <b-row v-if="!loading">
      <b-col cols="8">
        <!--
        <b-card
          class="mb-2"
          v-for="(article, index) in item.line_items"
          :key="index"
        >
          <b-row>
            <b-col cols="8"
              ><b>{{ article.title }}</b> <br />{{
                article.sku.split("_")[1]
              }}
              - {{ article.sku.split("_")[2] }}</b-col
            >
            <b-col cols="2">{{ article.quantity }}x</b-col>
            <b-col cols="2">{{ article.price }} €</b-col>
          </b-row>
        </b-card>
        <b-row class="ml-2 mr-4 mt-4">
          <b-col cols="6">
            <ul>
              <li>
                <b>Versand ({{ item.shipping_lines[0].title }})</b>
              </li>
              <li><b>MwSt.</b></li>
              <li class="mb-2"><b>Preis netto</b></li>
              <hr />
              <li><b>Gesamt</b></li>
            </ul>
          </b-col>
          <b-col cols="6" class="text-right">
            <ul>
              <li>{{ item.shipping_lines[0].price }} €</li>
              <li>{{ item.total_tax }} €</li>
              <li class="mb-2">{{ item.total_price - item.total_tax }} €</li>
              <hr />
              <li>{{ item.total_price }} €</li>
            </ul>
          </b-col>
          
        </b-row>
        -->
      </b-col>
      <b-col cols="4">
        <b-card class="mb-2">
          <h3>Status</h3>
          <ul>
            <li>
              <b>
                Ausführung:
              </b>
              {{}}
            </li>
            <li>
              <b>
                Zahlung:
              </b>
              {{ item.paymentStatusReadable }}
            </li>
            <li>
              <b>
                Nachbestellung:
              </b>
            </li>
          </ul>
        </b-card>
        <b-card class="mb-2">
          <h3>Rechnungsadresse</h3>
          <ul>
            <li>
              {{ item.firstname }}
              {{ item.lastname }}
            </li>
            <li></li>
          </ul>
        </b-card>
        <div v-if="item.paymentStatus === 'paid'">
          <b-button
            class="mb-2"
            block
            variant="primary"
            :href="
              '//pdfinvoice.apps.avada.io/admin/preview?id=' +
                item.id +
                '&shop=stickerfive.myshopify.com'
            "
            target="_blank"
            >Rechnung generieren</b-button
          >
          <b-button class="mb-2" block variant="success"
            >Bestellung ausführen</b-button
          >
          <b-button
            class="mb-2"
            block
            variant="warning"
            :href="
              '//stickerfive.myshopify.com/admin/apps/dhl-shipping/link/createlabel?id=' +
                item.id +
                '&shop=stickerfive.myshopify.com'
            "
            target="_blank"
            >Label generieren</b-button
          >
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store/index";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      items: (state) => state.orders.orders,
      loading: (state) => state.manage.loading,
    }),
    item() {
      let item = {};
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].id === parseFloat(this.id)) {
          item = this.items[i];
          break;
        }
      }
      return item;
    },
  },
  mounted() {
    if (!this.item.lastname) {
      console.log("There is no content Order");
      store.dispatch("getOrders");
    } else {
      console.log("there is something here");
    }
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding-left: 0px;
}
</style>
