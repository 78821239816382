import Vue from 'vue'
import Vuex from 'vuex'
import manage from './modules/manage'
import orders from "./modules/orders"
//import apiConfig from "@/apiConfig.js"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
    
  },
  actions: {

  },
  modules: {
    manage,
    orders
  }
})
