<template>
  <div>
    <b-alert show :variant="alert.type">
      <p>{{ alert.message }}</p>
    </b-alert>
  </div>
</template>

<script>
export default {
  props: {
    alert: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  margin: 0 auto;
  margin-bottom: 10px;
  p {
    margin-bottom: 0px;
  }
}
@media (max-width: 800px) {
  .alert {
    width: 90%;
  }
}
@media (min-width: 801px) {
  .alert {
    width: 40%;
  }
}
</style>
