<template>
  <div>
    <h1>Nachbestellung</h1>
    <b-card class="mt-5">
      <b-row class="text-center mt-4">
        <b-col cols="3">
          <h3>255</h3>
          <p>Sticker</p>
        </b-col>
        <b-col cols="3">
          <h3>3</h3>
          <p>Poster</p>
        </b-col>
        <b-col cols="3">
          <h3>2</h3>
          <p>Dibond</p>
        </b-col>
        <b-col cols="3">
          <h3>8</h3>
          <p>Tasse</p>
        </b-col>
        <b-col cols="5" class="mx-auto mt-5"
          ><b-button @click="giveMeZIP()" variant="primary" block
            >Druckdaten bereitstellen</b-button
          ></b-col
        >
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  methods: {
    giveMeZIP() {
      console.log("BRRRR");
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0px;
}
</style>
