const state = {
    user: {auth:false},
    notifications:[],
    loading: false,
    //Auth
    token: localStorage.getItem('user-token') || '',
    status: '',
}
const actions={
    addNotification({commit}, data){
        commit("ADD_NOTIFICATION", data)
    }
}
const mutations={
    ADD_NOTIFICATION(state, data){
        state.notifications.push(data)
        setTimeout(()=>{
          for(let i=0; i<state.notifications.length;i++){
            if(state.notifications[i].id===data.id){
              state.notifications.splice(i,1)
            }
          }},5000)
      },
      SET_USER(state){
          state.user.auth = true
          let user = localStorage.getItem("user")
          state.user.email = user.email
      },
      UNSET_USER(state){
        state.user.auth = false,
        state.user.email = ""
    },
    SET_LOADING(state){
        state.loading = true
    },
    UNSET_LOADING(state){
        state.loading = false
    }
}

export default {state,actions,mutations}