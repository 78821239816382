import axios from 'axios'


let header = {
  baseURL: `https://rest.stickerfive.de/api/`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 10000
}
if(localStorage.getItem("jwt") != null){
  header.headers.accessToken = localStorage.getItem("jwt");
}
const apiClient = axios.create(header)



export default {
  login(user) {
    return apiClient.post('user',user)
  },
  signUp(user) {
    return apiClient.put('user',user)
  },
  getOrders() {
    return apiClient.get('orders')
  },
}
