<template>
  <div>
    <h1>Registrieren</h1>
    <b-form @submit.prevent="signUp()">
      <b-form-group id="input-group-1" label="E-Mail" label-for="input-1">
        <b-form-input
          id="input-1"
          v-model="user.email"
          type="email"
          required
          placeholder="E-Mail eingeben"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="Passwort" label-for="input-2">
        <b-form-input
          id="input-2"
          v-model="user.password"
          type="password"
          required
          placeholder="Passwort eingeben"
        ></b-form-input>
      </b-form-group>
      <b-button type="submit" variant="primary" block
        >Account erstellen</b-button
      >
      <p>
        Bereits einen Account?
        <b-button variant="link" :to="{ name: 'Login' }"
          >Jetzt einloggen</b-button
        >
      </p>
    </b-form>
  </div>
</template>

<script>
import store from "@/store/index";
import apiCall from "@/services/api";
export default {
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    signUp() {
      if (this.user.password && this.user.password.length > 0) {
        //this.user.superAdminPassword = "bY8tz9ZYzdh5hAPe";
        //SERVER SIDE AUTH
        apiCall
          .signUp(this.user)
          .then((response) => {
            store.dispatch("addNotification", {
              type: "success",
              message: "Account erstellt",
            });
            //
            this.$router.push("/login");
            //
            console.log(response);
          })
          .catch(function(error) {
            store.dispatch("addNotification", {
              type: "danger",
              message: error.message,
            });
            console.error(error.message);
          });

        /*  let url = "http://localhost:3000/register"
                    if(this.is_admin != null || this.is_admin == 1) url = "http://localhost:3000/register-admin"
                    this.$http.post(url, {
                        name: this.name,
                        email: this.email,
                        password: this.password,
                        is_admin: this.is_admin
                    })
                    .then(response => {*/
      }
      /*})
                    .catch(error => {
                        console.error(error);
                    });*/
      /*} else {
        this.password = "";
        this.passwordConfirm = "";

        return alert("Passwords do not match");
      }*/
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-top: 100px;
  text-align: center;
}
form {
  text-align: left;
  margin: 0 auto;
  margin-top: 40px;
}
@media (max-width: 800px) {
  form {
    width: 90%;
  }
}
@media (min-width: 801px) {
  form {
    width: 40%;
  }
}
</style>
