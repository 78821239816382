<template>
  <div>
    <h1>Login</h1>
    <b-form @submit.prevent="login()">
      <b-form-group id="input-group-1" label="E-Mail" label-for="input-1">
        <b-form-input
          id="input-1"
          v-model="user.email"
          type="email"
          required
          placeholder="E-Mail eingeben"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="Passwort" label-for="input-2">
        <b-form-input
          id="input-2"
          v-model="user.password"
          type="password"
          required
          placeholder="Passwort eingeben"
        ></b-form-input>
      </b-form-group>
      <b-button type="submit" variant="primary" block>Einloggen</b-button>
      <p>
        Noch keinen Account?
        <b-button variant="link" :to="{ name: 'SignUp' }"
          >Registriere dich</b-button
        >
      </p>
    </b-form>
  </div>
</template>

<script>
import store from "@/store/index";
import apiCall from "@/services/api";
export default {
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      //NOTIFICATIONS
      /*
      if (this.user.email === "test@mail.de") {
        this.$store.dispatch("addNotification", {
          type: "success",
          id: Math.floor(Math.random() * 1000 + 1),
          message: "Hello",
        });
      }*/
      //if (this.password) {
      //SERVER CONNECTION

      apiCall
        .login(this.user)
        .then((response) => {
          store.dispatch("addNotification", {
            type: "success",
            message: "Login successful",
          });
          localStorage.setItem("user", JSON.stringify(this.user));
          localStorage.setItem("jwt", response.data.accessToken);
          if (localStorage.getItem("jwt") != null) {
            this.$store.commit("SET_USER");
            this.$emit("loggedIn");
            if (this.$route.params.nextUrl != null) {
              this.$router.push(this.$route.params.nextUrl);
            } else {
              this.$router.push({ name: "Home" });
            }
          }
          console.log(response.data);
        })
        .catch(function(error) {
          store.dispatch("addNotification", {
            type: "danger",
            message: error.message,
          });
          console.error(error.message);
        });
      //}
      /*
      //USER AUTH HINZUFÜGEN
      
      /*const { username, password } = this;
      this.$store.dispatch(AUTH_REQUEST, { username, password }).then(() => {
        this.$router.push("/");
      });*/
      //console.log(this.user);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
h1 {
  margin-top: 100px;
  text-align: center;
}
form {
  text-align: left;
  margin: 0 auto;
  margin-top: 40px;
}
@media (max-width: 800px) {
  form {
    width: 90%;
  }
}
@media (min-width: 801px) {
  form {
    width: 40%;
  }
}
</style>
