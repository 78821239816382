<template>
  <div>
    <h1>Bestellungen</h1>
    <!-- User Interface controls -->
    <b-row>
      <b-col lg="12" class=" mt-2 mb-3">
        <b-form-group class="mb-0">
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Wonach suchst du?"
            ></b-form-input>
            <!--<b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">X</b-button>
              </b-input-group-append>-->
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-row v-if="loading">
      <b-spinner
        v-if="loading"
        class="mx-auto mt-5 mb-5"
        label="Loading..."
        style="width: 3rem; height: 3rem;"
      ></b-spinner>
    </b-row>
    <b-table
      v-if="!loading"
      show-empty
      responsive
      stacked="md"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
      style=""
    >
      <template #cell(actions)="row">
        <b-button
          variant="light"
          size="sm"
          :to="{
            name: 'OrdersOrder',
            params: { id: row.item.id.toString() },
          }"
          class="mr-1"
        >
          <b-icon-chevron-right></b-icon-chevron-right>
        </b-button>
      </template>

      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">
              {{ key }}: {{ value }}
            </li>
          </ul>
        </b-card>
      </template>
    </b-table>
    <b-col sm="7" md="6" class="ml-auto">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="right"
        class="my-0"
      ></b-pagination>
    </b-col>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store/index";
export default {
  data() {
    return {
      /*items: [
        {
          id: 48328428349,
          order_nr: "#2344",
          status: "Abgeschlossen",
          payment: "Erhalten",
          total: 35.9,
          reorder: ["Poster", "Tasse"],
          isActive: true,
          article: ["", "", ""],
          name: { first: "Dickerson", last: "Macdonald" },
        },
        { isActive: false, age: 21, name: { first: "Larsen", last: "Shaw" } },
        {
          isActive: false,
          age: 9,
          name: { first: "Mini", last: "Navarro" },
        },
        { isActive: false, age: 89, name: { first: "Geneva", last: "Wilson" } },
        { isActive: true, age: 38, name: { first: "Jami", last: "Carney" } },
        { isActive: false, age: 27, name: { first: "Essie", last: "Dunlap" } },
        { isActive: true, age: 40, name: { first: "Thor", last: "Macdonald" } },
        {
          isActive: true,
          age: 87,
          name: { first: "Larsen", last: "Shaw" },
          _cellVariants: { age: "danger", isActive: "warning" },
        },
        { isActive: false, age: 26, name: { first: "Mitzi", last: "Navarro" } },
        {
          isActive: false,
          age: 22,
          name: { first: "Genevieve", last: "Wilson" },
        },
        { isActive: true, age: 38, name: { first: "John", last: "Carney" } },
        { isActive: false, age: 29, name: { first: "Dick", last: "Dunlap" } },
      ],*/
      fields: [
        {
          key: "orderNo",
          label: "Order Nr.",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: "firstname",
          label: "Vorname",
        },
        {
          key: "lastname",
          label: "Nachname",
        },

        {
          key: "paymentStatusReadable",
          label: "Zahlung",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: "fulfillment_status",
          label: "Ausführung",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: "articleCount",
          label: "Artikel",
          class: "text-center",
        },
        {
          key: "reorder_status",
          label: "Nachbestellung",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },

        {
          key: "totalPrice",
          label: "Gesamt",
          filterByFormatted: true,
        },
        { key: "actions", label: "" },
      ],
      currentPage: 1,
      perPage: 50,
      sortBy: "",
      sortDesc: false,
      sortDirection: "desc",
      filter: null,
      filterOn: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    ...mapState({
      items: (state) => state.orders.orders,
      loading: (state) => state.manage.loading,
    }),
    totalRows() {
      return this.items.length;
    },
  },
  mounted() {
    // Set the initial number of items
    store.dispatch("getOrders");
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style lang="scss" scoped></style>
