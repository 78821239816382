<template>
  <div id="app">
    <Notifications />
    <div id="nav" v-if="user.auth">
      <Nav />
    </div>
    <b-container class="mt-5">
      <router-view />
    </b-container>
  </div>
</template>
<script>
import Notifications from "@/components/Notifications.vue";
import Nav from "@/components/Nav.vue";
import { mapState } from "vuex";
export default {
  components: {
    Nav,
    Notifications,
  },
  computed: {
    ...mapState({
      user: (state) => state.manage.user,
    }),
  },
  mounted() {
    if (localStorage.getItem("jwt") != null) {
      this.$store.commit("SET_USER");
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #232323;
}
h1 {
  margin-top: 50px;
}

#nav .nav-link {
  font-weight: 400;
  color: #232323;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
#nav a:hover {
  text-decoration: none;
  opacity: 0.9;
}
#nav .dropdown-item:active {
  background-color: transparent;
}
</style>
