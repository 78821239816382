<template>
  <div class="notifications">
    <div v-for="(alert, index) in notifications" :key="index">
      <NotificationsAlert :alert="alert" />
    </div>
  </div>
</template>

<script>
import NotificationsAlert from "@/components/NotificationsAlert.vue";
import { mapState } from "vuex";
export default {
  components: {
    NotificationsAlert,
  },
  computed: {
    ...mapState({
      notifications: (state) => state.manage.notifications,
    }),
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  position: absolute;
  bottom: 20px;
  width: 100vw;
}
</style>
